//used by component and questions to order components/questions based on uuid list
export const orderByUUID = function(complist, uuidList) {
  let uuidLists = uuidList;
  if (typeof uuidLists === "string") {
    uuidLists = JSON.parse(uuidLists);
  }
  return uuidLists && uuidLists.length
    ? uuidLists
        .map((uuid) => complist.find((x) => x.uuid === uuid))
        .filter((el) => el !== null && el !== undefined)
    : complist;
};
